import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RENTERS_RENTERSINFO_LIST, 
  TOAST, 
  FETCH_RENTERS_RENTERSINFO_INIT,
  FETCH_RENTERS_RENTERSINFO_DETAIL,
  EXPORT_RENTERS_RENTERSINFO,
  CREATE_RENTERS_RENTERSINFO,
  EDIT_RENTERS_RENTERSINFO,
  INIT_CREATE_RENTERS_RENTERSINFO_SCREEN,
  RENTERS_RENTERSINFODD,
  RENTERS_RENTERSINFODELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../../types/Renters/RentersInfo.type";



  const formFieldValueMap = {
    id:null,
    description:"",
    modBy:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    RentersRentersInfoListview: [],
    RentersRentersInfoDD:[],
    isRentersRentersInfoDDSuccess: false,
    isRentersRentersInfoDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isRentersRentersInfoCreateSuccess: false,
    isRentersRentersInfoCreateError: false,
    isRentersRentersInfoDetailSuccess: false,
    isRentersRentersInfoDetailError: false,
    isRentersRentersInfoEditSuccess: false,
    isRentersRentersInfoEditError: false,
    isRentersRentersInfoExportSuccess: false,
    isRentersRentersInfoExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isRentersRentersInfoDeleteSuccess: false,
    isRentersRentersInfoDeleteError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Code",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Description",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const RentersRentersInfoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RENTERS_RENTERSINFO_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RENTERS_RENTERSINFO_DETAIL.START:
      case EXPORT_RENTERS_RENTERSINFO.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RENTERS_RENTERSINFO_LIST.START:
      case CREATE_RENTERS_RENTERSINFO.START:
        case RENTERS_RENTERSINFODELETE.START:
        case EDIT_RENTERS_RENTERSINFO.START:
          case RENTERS_RENTERSINFODD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RENTERS_RENTERSINFO_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          RentersRentersInfoListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRentersRentersInfoCreateSuccess: false,
          isRentersRentersInfoCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          RentersRentersInfoListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RENTERS_RENTERSINFO_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          RentersRentersInfoListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isRentersRentersInfoDeletionSuccess: false,
          isRentersRentersInfoDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RENTERS_RENTERSINFO_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isRentersRentersInfoDetailSuccess: true,
      };
    }
    case FETCH_RENTERS_RENTERSINFO_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRentersRentersInfoDetailSuccess: false,
        isRentersRentersInfoDetailError: true,
      };
    }

    //DD
    case RENTERS_RENTERSINFODD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        RentersRentersInfoDD:temp,
        isRentersRentersInfoDDSuccess: true,
        isRentersRentersInfoDDError: false,
      };
    }
    case RENTERS_RENTERSINFODD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRentersRentersInfoDDSuccess: false,
        isRentersRentersInfoDDError: true,
      };
    }
    //END DD
//DELETE

case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
  const shouldOpenDeleteConfirmationPopup = action.payload;
  return {
    ...state,
    shouldOpenDeleteConfirmationPopup,
    isRentersRentersInfoDeleteSuccess: false,
    isRentersRentersInfoDeleteError: false,
  };
}
case RENTERS_RENTERSINFODELETE.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    isRentersRentersInfoDeleteSuccess: true,
    isRentersRentersInfoDeleteError: false,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
case RENTERS_RENTERSINFODELETE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isRentersRentersInfoDeleteSuccess: false,
    isRentersRentersInfoDeleteError: true,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
//END DD   
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RENTERS_RENTERSINFO.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRentersRentersInfoExportSuccess: false,
    isRentersRentersInfoExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RENTERS_RENTERSINFO.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"renters-info.xls");
   return {
    ...state,
    isRentersRentersInfoExportSuccess: true,
    isRentersRentersInfoExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RENTERS_RENTERSINFO.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRentersRentersInfoEditSuccess: false,
    isRentersRentersInfoEditError: true,
  };
}

case EDIT_RENTERS_RENTERSINFO.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRentersRentersInfoEditSuccess: true,
    isRentersRentersInfoEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RENTERS_RENTERSINFO_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RENTERS_RENTERSINFO.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRentersRentersInfoCreateSuccess: false,
    isRentersRentersInfoCreateError: true,
  };
}
case CREATE_RENTERS_RENTERSINFO.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRentersRentersInfoCreateSuccess: true,
    isRentersRentersInfoCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isRentersRentersInfoCreateSuccess: false,
          isRentersRentersInfoCreateError: false,
          isRentersRentersInfoEditError: false,
          isRentersRentersInfoEditSuccess: false,
          isRentersRentersInfoDetailError: false
        };
      }
      default:
        return state;
    }
  };
  