import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
    fetchRentersRentersInfo,
    initializeRentersRentersInfoScreen,
    setDeleteConfirmationPopupVisibility,
    exportRentersRentersInfo
  } from "../../../redux/actions/Renters/RentersRentersInfo";
import RentersRentersInfo from '../../../component/Renters/RentersRentersInfo';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../services/PostAPI';
import { APP_URLS } from '../../../utils/constant';
function RentersRentersInfoScreen(props) {
    const {
        fetchRentersRentersInfo,
        initRentersRentersInfoScreen,
        showPageLevelLoader,
        RentersRentersInfoListview,
        paginationInfo,
        exportRentersRentersInfo,
        isRentersRentersInfoExportError,
        isRentersRentersInfoExportSuccess,
        isLoading,
      } = props;
const navigate = useNavigate ();
      useEffect(() => {
        initRentersRentersInfoScreen();
        fetchRentersRentersInfo({ sortField: 'id',userId:localStorage.getItem("userId") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newProps={
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchRentersRentersInfo(newProps);
      };
      const handleCreateRentersRentersInfo =() =>{
      navigate(APP_URLS.RENTERS_RENTERSINFO.CREATE);
    }
      const editRentersRentersInfoRoute = (id) => {
        const path = generatePath(APP_URLS.RENTERS_RENTERSINFO.EDIT, {
          id,
        });
        navigate(path);
      };
const RentersRentersInfoProps = {
  handleCreateRentersRentersInfo,
  exportRentersRentersInfo,
        isRentersRentersInfoExportError,
        isRentersRentersInfoExportSuccess,
  editRentersRentersInfoRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRentersRentersInfos: loadLazyData,
  ...companyData,
  tableData: {
    columns: RentersRentersInfoListview?.columns,
    rows: RentersRentersInfoListview?.rows,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RentersRentersInfo
    {...RentersRentersInfoProps}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRentersRentersInfoScreen: () => dispatch(initializeRentersRentersInfoScreen()),
      fetchRentersRentersInfo: (payload) => dispatch(fetchRentersRentersInfo(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRentersRentersInfo: (payload) => dispatch(exportRentersRentersInfo(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRentersRentersInfoCreateSuccess: selectFromRentersRentersInfoReducer(
        state,
        "isRentersRentersInfoCreateSuccess"
      ),
      RentersRentersInfoListview: selectFromRentersRentersInfoReducer(
        state,
        "RentersRentersInfoListview"
      ),
      showPageLevelLoader: selectFromRentersRentersInfoReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRentersRentersInfoReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRentersRentersInfoReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRentersRentersInfoReducer(
        state,
        "isFetchingGridData"
      ),
      isRentersRentersInfoExportError: selectFromRentersRentersInfoReducer(
        state,
        "isRentersRentersInfoExportError"
      ),
      isRentersRentersInfoExportSuccess: selectFromRentersRentersInfoReducer(
        state,
        "isRentersRentersInfoExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRentersRentersInfoReducer = (state, path) => {
  return selectRentersRentersInfoReducer(state)[path];
};

const selectRentersRentersInfoReducer = ({ RentersRentersInfoReducer }) => {
  return RentersRentersInfoReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RentersRentersInfoScreen);
