import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { RentersRentersInfoServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RENTERS_RENTERSINFO_INIT, 
  FETCH_RENTERS_RENTERSINFO_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RENTERS_RENTERSINFO_SCREEN,
  CREATE_RENTERS_RENTERSINFO,
  EDIT_RENTERS_RENTERSINFO,
  TOAST,
  FETCH_RENTERS_RENTERSINFO_DETAIL,
  EXPORT_RENTERS_RENTERSINFO,
  RENTERS_RENTERSINFODD,
  RENTERS_RENTERSINFODELETE
} from "../../../types/Renters/RentersInfo.type";
export const initializeRentersRentersInfoScreen = () => ({ type: FETCH_RENTERS_RENTERSINFO_INIT });

export const RentersRentersInfoFetchStart = (payload) => ({
  type: FETCH_RENTERS_RENTERSINFO_LIST.START,
  payload,
});

export const RentersRentersInfoFetchSuccess = (payload) => ({
  type:FETCH_RENTERS_RENTERSINFO_LIST.SUCCESS,
  payload,
});

export const RentersRentersInfoFetchError = (payload) => ({
  type: FETCH_RENTERS_RENTERSINFO_LIST.ERROR,
  payload,
});

export const fetchRentersRentersInfo = (payload) => {
  return (dispatch) => {
    dispatch(RentersRentersInfoFetchStart(payload));
    RentersRentersInfoServices.fetchRentersRentersInfo(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersRentersInfoFetchSuccess(data));
      } else {
        dispatch(RentersRentersInfoFetchError(data));
      }
    });
  };
};



// DD
export const RentersRentersInfoDDFetchStart = (payload) => ({
  type: RENTERS_RENTERSINFODD.START,
  payload,
});

export const RentersRentersInfoDDFetchSuccess = (payload) => ({
  type:RENTERS_RENTERSINFODD.SUCCESS,
  payload,
});

export const RentersRentersInfoDDFetchError = (payload) => ({
  type: RENTERS_RENTERSINFODD.ERROR,
  payload,
});

export const fetchRentersRentersInfoDD = (payload) => {
  return (dispatch) => {
    dispatch(RentersRentersInfoDDFetchStart(payload));
    RentersRentersInfoServices.fetchRentersRentersInfoDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersRentersInfoDDFetchSuccess(data));
      } else {
        dispatch(RentersRentersInfoDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const RentersRentersInfoDetailFetchStart = (payload) => ({
  type: FETCH_RENTERS_RENTERSINFO_DETAIL.START,
  payload,
});

export const RentersRentersInfoDetailFetchSuccess = (payload) => ({
  type: FETCH_RENTERS_RENTERSINFO_DETAIL.SUCCESS,
  payload,
});

export const RentersRentersInfoDetailFetchError = (payload) => ({
  type: FETCH_RENTERS_RENTERSINFO_DETAIL.ERROR,
  payload,
});

export const fetchRentersRentersInfoById = (payload) => {
  return (dispatch) => {
    dispatch(RentersRentersInfoDetailFetchStart(payload));

    RentersRentersInfoServices
      .fetchRentersRentersInfoById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersRentersInfoDetailFetchSuccess(data));
        } else {
          dispatch(RentersRentersInfoDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    RentersRentersInfoServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRentersRentersInfoScreenInitStart = (payload) => ({
  type: INIT_CREATE_RENTERS_RENTERSINFO_SCREEN,
  payload,
});

export const initCreateRentersRentersInfoScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRentersRentersInfoScreenInitStart());
  };
};


export const createRentersRentersInfoStart = () => ({
  type: CREATE_RENTERS_RENTERSINFO.START,
});

export const createRentersRentersInfoSuccess = (payload) => ({
  type: CREATE_RENTERS_RENTERSINFO.SUCCESS,
  payload,
});

export const createRentersRentersInfoError = (payload) => ({
  type: CREATE_RENTERS_RENTERSINFO.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRentersRentersInfo = (payload) => {
  return (dispatch) => {
    dispatch(createRentersRentersInfoStart());
    RentersRentersInfoServices.createRentersRentersInfo(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRentersRentersInfoSuccess());
        goBack();
      } else {
        dispatch(createRentersRentersInfoError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRentersRentersInfoStart = (payload) => ({
  type: EDIT_RENTERS_RENTERSINFO.START,
  payload
});

export const editRentersRentersInfoSuccess = (payload) => ({
  type: EDIT_RENTERS_RENTERSINFO.SUCCESS,
  payload,
});

export const editRentersRentersInfoError = (payload) => ({
  type: EDIT_RENTERS_RENTERSINFO.ERROR,
  payload,
});

export const editRentersRentersInfo = (payload) => {
  return (dispatch) => {
    dispatch(editRentersRentersInfoStart());
    RentersRentersInfoServices.editRentersRentersInfo(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRentersRentersInfoSuccess());
        goBack();
      } else {
        dispatch(editRentersRentersInfoError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRentersRentersInfoRecordStart = (payload) => ({
  type: EXPORT_RENTERS_RENTERSINFO.START,
  payload
});

export const exportRentersRentersInfoRecordSuccess = (payload) => ({
  type: EXPORT_RENTERS_RENTERSINFO.SUCCESS,
  payload,
});

export const exportRentersRentersInfoRecordError = (payload) => ({
  type: EXPORT_RENTERS_RENTERSINFO.ERROR,
  payload,
});

export const exportRentersRentersInfo = (payload) => {
  return (dispatch) => {
    dispatch(exportRentersRentersInfoRecordStart());
    RentersRentersInfoServices.exportRentersRentersInfoRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRentersRentersInfoRecordSuccess(data));
      } else {
        dispatch(exportRentersRentersInfoRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const RentersRentersInfoDeleteStart = (payload) => ({
  type: RENTERS_RENTERSINFODELETE.START,
  payload,
});

export const RentersRentersInfoDeleteSuccess = (payload) => ({
  type: RENTERS_RENTERSINFODELETE.SUCCESS,
  payload,
});

export const RentersRentersInfoDeleteError = (payload) => ({
  type: RENTERS_RENTERSINFODELETE.ERROR,
  payload,
});

export const deleteRentersRentersInfo = (payload) => {
  return (dispatch) => {
    dispatch(RentersRentersInfoDeleteStart(payload));

    RentersRentersInfoServices
      .deleteRentersRentersInfo(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersRentersInfoDeleteSuccess(data));
          dispatch(fetchRentersRentersInfo(payload?.params));
        } else {
          dispatch(RentersRentersInfoDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/