

export const FETCH_RENTERS_VEHICLEINQUIRY_LIST = {
    START: 'FETCH_RENTERS_VEHICLEINQUIRY_LIST_START',
    SUCCESS: 'FETCH_RENTERS_VEHICLEINQUIRY_LIST_SUCCESS',
    ERROR: 'FETCH_RENTERS_VEHICLEINQUIRY_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RENTERS_VEHICLEINQUIRY_INIT="FETCH_RENTERS_VEHICLEINQUIRY_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RENTERS_VEHICLEINQUIRY_SCREEN =
'INIT_CREATE_RENTERS_VEHICLEINQUIRY_SCREEN';

export const CREATE_RENTERS_VEHICLEINQUIRY = {
START: 'CREATE_RENTERS_VEHICLEINQUIRY_START',
SUCCESS: 'CREATE_RENTERS_VEHICLEINQUIRY_SUCCESS',
ERROR: 'CREATE_RENTERS_VEHICLEINQUIRY_ERROR',
};

export const EDIT_RENTERS_VEHICLEINQUIRY = {
START: 'EDIT_RENTERS_VEHICLEINQUIRY_START',
SUCCESS: 'EDIT_RENTERS_VEHICLEINQUIRY_SUCCESS',
ERROR: 'EDIT_RENTERS_VEHICLEINQUIRY_ERROR',
};

export const FETCH_RENTERS_VEHICLEINQUIRY_DETAIL = {
  START: 'FETCH_RENTERS_VEHICLEINQUIRY_DETAIL_START',
  SUCCESS: 'FETCH_RENTERS_VEHICLEINQUIRY_DETAIL_SUCCESS',
  ERROR: 'FETCH_RENTERS_VEHICLEINQUIRY_DETAIL_ERROR',
};
export const EXPORT_RENTERS_VEHICLEINQUIRY = {
  START: 'EXPORT_RENTERS_VEHICLEINQUIRY_START',
  SUCCESS: 'EXPORT_RENTERS_VEHICLEINQUIRY_SUCCESS',
  ERROR: 'EXPORT_RENTERS_VEHICLEINQUIRY_ERROR',
};
export const RENTERS_VEHICLEINQUIRYDD = {
  START: 'RENTERS_VEHICLEINQUIRYDD_START',
  SUCCESS: 'RENTERS_VEHICLEINQUIRYDD_SUCCESS',
  ERROR: 'RENTERS_VEHICLEINQUIRYDD_ERROR',
};
export const RENTERS_VEHICLEINQUIRYDELETE = {
  START: 'RENTERS_VEHICLEINQUIRYDELETE_START',
  SUCCESS: 'RENTERS_VEHICLEINQUIRYDELETE_SUCCESS',
  ERROR: 'RENTERS_VEHICLEINQUIRYDELETE_ERROR',
};

