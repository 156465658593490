export const basicDetail =
[
  {
    name:"firstName",
     "rule": {
        "required": true
      },
    label:"First Name",
    type:"text"
  },
  {
    name:"lastName",
     "rule": {
        "required": true
      },
    label:"Last Name",
    type:"text"
  },
  {
    name:"emailID",
     "rule": {
        "required": true
      },
    label:"Email ID",
    type:"text"
  },
  {
    name:"phoneNumber",
     "rule": {
        "required": true
      },
    label:"Phone Number",
    type:"text"
  },
  {
    name:"dailyBudget",
     "rule": {
        "required": true
      },
    label:"Daily Budget",
    type:"text"
  },
  {
    name:"vehicleName",
     "rule": {
        "required": true
      },
    label:"Vehicle Name",
    type:"text"
  },
  {
    name:"location",
     "rule": {
        "required": true
      },
    label:"Location",
    type:"text"
  },
  {
    name:"pickupDate",
     "rule": {
        "required": true
      },
    label:"Pickup Date",
    type:"text"
  },
  {
    name:"dropoffDate",
     "rule": {
        "required": true
      },
    label:"Dropoff Date",
    type:"text"
  },
  {
    name:"Reply",
     "rule": {
        "required": true
      },
    label:"Reply",
    type:"editor"
  }
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const typeDD =
[
  { id:1, name:"Flat"},
  { id:2, name:"Percentage"}
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]
