import React, { useEffect, useMemo, useState } from "react";
import { useForm} from "react-hook-form";
import { Button } from "primereact/button";
import { TabView, TabPanel } from 'primereact/tabview';
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import { goBack } from "../../../../utils/gotoback";
import { loadingShow } from "../../../../services/PostAPI";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import {  basicDetail, editMode, statusDD, typeDD} from "../CreateRentersVehicleInquiry/constant"
import { Box, Container, Grid, Typography } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import EzControlledCheckBoxWithLabel from "../../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { RentersRentersInfoBreadcrumb } from "../../RentersRentersInfo/CreateRentersRentersInfo/constant";
import EzControlledEditorFloatingLabel from "../../../../common/EzForm/EzControlledEditor/WithFloatingLabel";
const CreateRentersVehicleInquiryComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateRentersVehicleInquiryScreen,
    createRentersVehicleInquiry,
    editRentersVehicleInquiry,
    isLoading,
    isRentersVehicleInquiryCreateSuccess,
    isRentersVehicleInquiryCreateError,
    isRentersVehicleInquiryDetailSuccess,
    isRentersVehicleInquiryDetailError,
    isRentersVehicleInquiryEditSuccess,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
  } = props;
  console.log("check props of compoemnt", props);
 

  const classes = useStyles()
 
  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } =  useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });

  const [activeIndex, setActiveIndex] = useState(0);
  

   const navigateToRentersVehicleInquiryScreen = () => {
    window.location.replace("/#"+APP_URLS.RENTERS_VEHICLEINQUIRY.HOME)
  };
  useEffect(() => {
    
    if (isRentersVehicleInquiryCreateSuccess || isRentersVehicleInquiryEditSuccess) {
      reset();
    }
  }, [isRentersVehicleInquiryCreateSuccess]);
  useEffect(() => {
    if (isRentersVehicleInquiryDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isRentersVehicleInquiryDetailSuccess]);
  useEffect(() => {
    initCreateRentersVehicleInquiryScreen();
  }, []);
  
  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");
    
    const stringifiedData = {
      ...newData,
    }

      isEdit
        ? editRentersVehicleInquiry({ id: data.id, data: stringifiedData })
        : createRentersVehicleInquiry(stringifiedData);
    
  };
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
};

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  
  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Reply to Vehicle Inquiry</h4>
          </div>
          <div className="buttonarea">
          <Button
            tooltip={"Back to Vehicle Inquiry"}
            tooltipOptions={{ position: "left" }}
            label={"Back to Vehicle Inquiry"}
            // icon={"pi pi-arrow-left"}
            className={`p-button `}
            style={{ borderRadius: "10px" }}
            onClick={navigateToRentersVehicleInquiryScreen}
          />
        </div>
        </div>
      </div>
    );
  };
 
  return (
    <Container maxWidth="lg">



      <Breadcrumb list={RentersRentersInfoBreadcrumb } />
      <EzToast {...getToastProps()} /> 

      <div className="containerbox" className={classes.bg}>

      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Reply">
                {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
         
              <Grid container spacing={2}>
                {basicDetail?.map((item) => (
                  item.name === "status" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={item?.rule}
                        optionLabel={"name"}
                        optionValue={"id"}
                        options={statusDD}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Grid>
                  ):

                  item.type === "date" ? (
                    <Grid item xs={3} key={item.name}>
                      <EzControlledCalendarWithFloatingLabel
        id={item.name}
        name={item.name}
        {...register(item.name)}
        showTime={false}
        maxDate={new Date(2090, 0, 1)}
        dateFormat="yy/mm/dd" 
        control={control}
        isError={errors[item.name]}
        errorMsg={getFormErrorMessage(item.name)}
        label={item.label}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
      />
                    </Grid>
                  )
                  :
                  item.type === "editor" ? (
                    <Grid item xs={12} key={item.name}>
                  
                  <EzControlledEditorFloatingLabel
                    name={item?.name}
                    control={control}
                    label={item.label}
                    rules={item?.rule}
                    isError={errors[item?.name]}
                    errorMsg={getFormErrorMessage(item?.name)}
                  />
                     
                    </Grid>
                  )
                  
                  
                  
                  :(
                  (<Grid item xs={3} key={item.name}>
                      <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Grid>))
                ))}

              </Grid>
            
            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
           

            
                  <React.Fragment>
                    <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                        type="submit"
                        label={isEdit ? "Send Reply" : "Send Reply"}
                        className="mt-2 p-button-raised"
                      />
                    </div>

                  </React.Fragment>
               
              </div>
            </div>
            {isEdit &&  (<Grid className="modsection" container spacing={2}>
              { editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                <EzControlledInputWithFloatingLabel
                        name={item?.name}
                        control={control}
                        disabled={item?.disabled}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Grid>
              )
            )
          }
          </Grid>)
}
          </form>
        </div>
                </TabPanel>
                <TabPanel header="Email">
                <Grid item xs={12} marginTop={"20px"}>
                  
                  <EzControlledEditorFloatingLabel
                    name='email'
                    control={control}
                    label='Email'
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors['email']}
                    errorMsg={getFormErrorMessage('email')}
                  />
                     
                    </Grid>
                    <React.Fragment>
                    <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                      <Button
                        type="submit"
                        label={isEdit ? "Send Email" : "Send Email"}
                        className="mt-2 p-button-raised"
                      />
                    </div>

                  </React.Fragment>
                </TabPanel>
                <TabPanel header="History">
                  <Typography>History data</Typography>
                    <p>History</p>
                </TabPanel>
            </TabView>
      
      </div>
    </Container>
  );
};

export default CreateRentersVehicleInquiryComponent;
