import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { RentersRegisteredRentersServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RENTERS_REGISTEREDRENTERS_INIT, 
  FETCH_RENTERS_REGISTEREDRENTERS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RENTERS_REGISTEREDRENTERS_SCREEN,
  CREATE_RENTERS_REGISTEREDRENTERS,
  EDIT_RENTERS_REGISTEREDRENTERS,
  TOAST,
  FETCH_RENTERS_REGISTEREDRENTERS_DETAIL,
  EXPORT_RENTERS_REGISTEREDRENTERS,
  RENTERS_REGISTEREDRENTERSDD,
  RENTERS_REGISTEREDRENTERSDELETE
} from "../../../types/Renters/RegisteredRenters.type";
export const initializeRentersRegisteredRentersScreen = () => ({ type: FETCH_RENTERS_REGISTEREDRENTERS_INIT });

export const RentersRegisteredRentersFetchStart = (payload) => ({
  type: FETCH_RENTERS_REGISTEREDRENTERS_LIST.START,
  payload,
});

export const RentersRegisteredRentersFetchSuccess = (payload) => ({
  type:FETCH_RENTERS_REGISTEREDRENTERS_LIST.SUCCESS,
  payload,
});

export const RentersRegisteredRentersFetchError = (payload) => ({
  type: FETCH_RENTERS_REGISTEREDRENTERS_LIST.ERROR,
  payload,
});

export const fetchRentersRegisteredRenters = (payload) => {
  return (dispatch) => {
    dispatch(RentersRegisteredRentersFetchStart(payload));
    RentersRegisteredRentersServices.fetchRentersRegisteredRenters(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersRegisteredRentersFetchSuccess(data));
      } else {
        dispatch(RentersRegisteredRentersFetchError(data));
      }
    });
  };
};



// DD
export const RentersRegisteredRentersDDFetchStart = (payload) => ({
  type: RENTERS_REGISTEREDRENTERSDD.START,
  payload,
});

export const RentersRegisteredRentersDDFetchSuccess = (payload) => ({
  type:RENTERS_REGISTEREDRENTERSDD.SUCCESS,
  payload,
});

export const RentersRegisteredRentersDDFetchError = (payload) => ({
  type: RENTERS_REGISTEREDRENTERSDD.ERROR,
  payload,
});

export const fetchRentersRegisteredRentersDD = (payload) => {
  return (dispatch) => {
    dispatch(RentersRegisteredRentersDDFetchStart(payload));
    RentersRegisteredRentersServices.fetchRentersRegisteredRentersDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(RentersRegisteredRentersDDFetchSuccess(data));
      } else {
        dispatch(RentersRegisteredRentersDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const RentersRegisteredRentersDetailFetchStart = (payload) => ({
  type: FETCH_RENTERS_REGISTEREDRENTERS_DETAIL.START,
  payload,
});

export const RentersRegisteredRentersDetailFetchSuccess = (payload) => ({
  type: FETCH_RENTERS_REGISTEREDRENTERS_DETAIL.SUCCESS,
  payload,
});

export const RentersRegisteredRentersDetailFetchError = (payload) => ({
  type: FETCH_RENTERS_REGISTEREDRENTERS_DETAIL.ERROR,
  payload,
});

export const fetchRentersRegisteredRentersById = (payload) => {
  return (dispatch) => {
    dispatch(RentersRegisteredRentersDetailFetchStart(payload));

    RentersRegisteredRentersServices
      .fetchRentersRegisteredRentersById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersRegisteredRentersDetailFetchSuccess(data));
        } else {
          dispatch(RentersRegisteredRentersDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    RentersRegisteredRentersServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createRentersRegisteredRentersScreenInitStart = (payload) => ({
  type: INIT_CREATE_RENTERS_REGISTEREDRENTERS_SCREEN,
  payload,
});

export const initCreateRentersRegisteredRentersScreen = (payload) => {
  return (dispatch) => {
    dispatch(createRentersRegisteredRentersScreenInitStart());
  };
};


export const createRentersRegisteredRentersStart = () => ({
  type: CREATE_RENTERS_REGISTEREDRENTERS.START,
});

export const createRentersRegisteredRentersSuccess = (payload) => ({
  type: CREATE_RENTERS_REGISTEREDRENTERS.SUCCESS,
  payload,
});

export const createRentersRegisteredRentersError = (payload) => ({
  type: CREATE_RENTERS_REGISTEREDRENTERS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createRentersRegisteredRenters = (payload) => {
  return (dispatch) => {
    dispatch(createRentersRegisteredRentersStart());
    RentersRegisteredRentersServices.createRentersRegisteredRenters(payload).then((data) => {
      if (!data.isError) {
        dispatch(createRentersRegisteredRentersSuccess());
        goBack();
      } else {
        dispatch(createRentersRegisteredRentersError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editRentersRegisteredRentersStart = (payload) => ({
  type: EDIT_RENTERS_REGISTEREDRENTERS.START,
  payload
});

export const editRentersRegisteredRentersSuccess = (payload) => ({
  type: EDIT_RENTERS_REGISTEREDRENTERS.SUCCESS,
  payload,
});

export const editRentersRegisteredRentersError = (payload) => ({
  type: EDIT_RENTERS_REGISTEREDRENTERS.ERROR,
  payload,
});

export const editRentersRegisteredRenters = (payload) => {
  return (dispatch) => {
    dispatch(editRentersRegisteredRentersStart());
    RentersRegisteredRentersServices.editRentersRegisteredRenters(payload).then((data) => {
      if (!data.isError) {
        dispatch(editRentersRegisteredRentersSuccess());
        goBack();
      } else {
        dispatch(editRentersRegisteredRentersError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportRentersRegisteredRentersRecordStart = (payload) => ({
  type: EXPORT_RENTERS_REGISTEREDRENTERS.START,
  payload
});

export const exportRentersRegisteredRentersRecordSuccess = (payload) => ({
  type: EXPORT_RENTERS_REGISTEREDRENTERS.SUCCESS,
  payload,
});

export const exportRentersRegisteredRentersRecordError = (payload) => ({
  type: EXPORT_RENTERS_REGISTEREDRENTERS.ERROR,
  payload,
});

export const exportRentersRegisteredRenters = (payload) => {
  return (dispatch) => {
    dispatch(exportRentersRegisteredRentersRecordStart());
    RentersRegisteredRentersServices.exportRentersRegisteredRentersRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportRentersRegisteredRentersRecordSuccess(data));
      } else {
        dispatch(exportRentersRegisteredRentersRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const RentersRegisteredRentersDeleteStart = (payload) => ({
  type: RENTERS_REGISTEREDRENTERSDELETE.START,
  payload,
});

export const RentersRegisteredRentersDeleteSuccess = (payload) => ({
  type: RENTERS_REGISTEREDRENTERSDELETE.SUCCESS,
  payload,
});

export const RentersRegisteredRentersDeleteError = (payload) => ({
  type: RENTERS_REGISTEREDRENTERSDELETE.ERROR,
  payload,
});

export const deleteRentersRegisteredRenters = (payload) => {
  return (dispatch) => {
    dispatch(RentersRegisteredRentersDeleteStart(payload));

    RentersRegisteredRentersServices
      .deleteRentersRegisteredRenters(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(RentersRegisteredRentersDeleteSuccess(data));
          dispatch(fetchRentersRegisteredRenters(payload?.params));
        } else {
          dispatch(RentersRegisteredRentersDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/