import { arrayBufferDownload, arrayBufferDownloadExcel, downloadCSV } from "../../../../utils/download";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../../utils/table/gridUtils";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RENTERS_REGISTEREDRENTERS_LIST, 
  TOAST, 
  FETCH_RENTERS_REGISTEREDRENTERS_INIT,
  FETCH_RENTERS_REGISTEREDRENTERS_DETAIL,
  EXPORT_RENTERS_REGISTEREDRENTERS,
  CREATE_RENTERS_REGISTEREDRENTERS,
  EDIT_RENTERS_REGISTEREDRENTERS,
  INIT_CREATE_RENTERS_REGISTEREDRENTERS_SCREEN,
  RENTERS_REGISTEREDRENTERSDD,
  RENTERS_REGISTEREDRENTERSDELETE,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY
} from "../../../types/Renters/RegisteredRenters.type";



  const formFieldValueMap = {
    id:null,
    description:"",
    modBy:"",
    modTime:"",
    status:1,
    title:"",
    userId:""
  };
  
  const INITIAL_STATE = {
    RentersRegisteredRentersListview: [],
    RentersRegisteredRentersDD:[],
    isRentersRegisteredRentersDDSuccess: false,
    isRentersRegisteredRentersDDError: false,
    isLoading: false,
    error: null,
    formFieldValueMap,
    isRentersRegisteredRentersCreateSuccess: false,
    isRentersRegisteredRentersCreateError: false,
    isRentersRegisteredRentersDetailSuccess: false,
    isRentersRegisteredRentersDetailError: false,
    isRentersRegisteredRentersEditSuccess: false,
    isRentersRegisteredRentersEditError: false,
    isRentersRegisteredRentersExportSuccess: false,
    isRentersRegisteredRentersExportError: false,
    isUploadImageSuccess: false,
    isUploadImageError: false,
    imageUploadResponse:null,
    isRentersRegisteredRentersDeleteSuccess: false,
    isRentersRegisteredRentersDeleteError: false,
  };
  
  const tranformToGridView = (data) => {
    const rows = data.map((item) => {
      const {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      } = item;
  
      const transformedValues = {
        crtBy,
crtTime,
description,
id,
modBy,
modTime,
status,
title
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
  
    const columns = [
      {
        colLabel: "ID",
        dataKey: "id",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Rate Code",
        dataKey: "title",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Description",
        dataKey: "description",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
     
      {
        colLabel: "Crt By",
        dataKey: "crtBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Crt Time",
        dataKey: "crtTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Mod By",
        dataKey: "modBy",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Edit Time",
        dataKey: "modTime",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Status",
        dataKey: "status",
        extraProps: {
          isSortable: true,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
  
    return {
      datas: { columns, rows },
    };
  };

  export const RentersRegisteredRentersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_RENTERS_REGISTEREDRENTERS_INIT:
        return { ...INITIAL_STATE, isLoadingPage: true };
      case FETCH_RENTERS_REGISTEREDRENTERS_DETAIL.START:
      case EXPORT_RENTERS_REGISTEREDRENTERS.START:
        case UPLOAD_IMAGE.START:
      case FETCH_RENTERS_REGISTEREDRENTERS_LIST.START:
      case CREATE_RENTERS_REGISTEREDRENTERS.START:
        case RENTERS_REGISTEREDRENTERSDELETE.START:
        case EDIT_RENTERS_REGISTEREDRENTERS.START:
          case RENTERS_REGISTEREDRENTERSDD.START:
        return { ...state, isLoading: true };
  
      case FETCH_RENTERS_REGISTEREDRENTERS_LIST.SUCCESS: {
        const { page, data } = action.payload;
       
        let resetState = {
          RentersRegisteredRentersListview: [],
          paginationInfo:null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isRentersRegisteredRentersCreateSuccess: false,
          isRentersRegisteredRentersCreateError: false,
        };
        if (!data) {
          return resetState;
        }
        const { number, size, totalElements, totalPages } = page;
        const { datas } = tranformToGridView(data);
        return {
          ...resetState,
          RentersRegisteredRentersListview: datas,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages: totalPages,
            pageNumber: number,
          },
        };
      }
      case FETCH_RENTERS_REGISTEREDRENTERS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          RentersRegisteredRentersListview: [],
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isRentersRegisteredRentersDeletionSuccess: false,
          isRentersRegisteredRentersDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }

    case FETCH_RENTERS_REGISTEREDRENTERS_DETAIL.SUCCESS: {
      const temp = action.payload;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
              temp[key] = action.payload[key];
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isRentersRegisteredRentersDetailSuccess: true,
      };
    }
    case FETCH_RENTERS_REGISTEREDRENTERS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRentersRegisteredRentersDetailSuccess: false,
        isRentersRegisteredRentersDetailError: true,
      };
    }

    //DD
    case RENTERS_REGISTEREDRENTERSDD.SUCCESS: {
      const temp = action.payload;
 
      return {
        ...state,
        isLoading: false,
        RentersRegisteredRentersDD:temp,
        isRentersRegisteredRentersDDSuccess: true,
        isRentersRegisteredRentersDDError: false,
      };
    }
    case RENTERS_REGISTEREDRENTERSDD.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isRentersRegisteredRentersDDSuccess: false,
        isRentersRegisteredRentersDDError: true,
      };
    }
    //END DD
//DELETE

case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
  const shouldOpenDeleteConfirmationPopup = action.payload;
  return {
    ...state,
    shouldOpenDeleteConfirmationPopup,
    isRentersRegisteredRentersDeleteSuccess: false,
    isRentersRegisteredRentersDeleteError: false,
  };
}
case RENTERS_REGISTEREDRENTERSDELETE.SUCCESS: {
  const temp = action.payload;

  return {
    ...state,
    isLoading: false,
    isRentersRegisteredRentersDeleteSuccess: true,
    isRentersRegisteredRentersDeleteError: false,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
case RENTERS_REGISTEREDRENTERSDELETE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    error,
    isRentersRegisteredRentersDeleteSuccess: false,
    isRentersRegisteredRentersDeleteError: true,
    shouldOpenDeleteConfirmationPopup: false,
  };
}
//END DD   
    
// UPLOAD_IMAGE Start
case UPLOAD_IMAGE.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isUploadImageSuccess: false,
    isUploadImageError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case UPLOAD_IMAGE.SUCCESS: {
  const  {data}  = action.payload;
   return {
    ...state,
    imageUploadResponse:data,
    isUploadImageSuccess: true,
    isUploadImageError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// UPLOAD_IMAGE End

// Export Start
case EXPORT_RENTERS_REGISTEREDRENTERS.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isRentersRegisteredRentersExportSuccess: false,
    isRentersRegisteredRentersExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_RENTERS_REGISTEREDRENTERS.SUCCESS: {
  const  data  = action.payload;
  arrayBufferDownloadExcel(data,"registered-renters.xls");
   return {
    ...state,
    isRentersRegisteredRentersExportSuccess: true,
    isRentersRegisteredRentersExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End

// EDIT
case EDIT_RENTERS_REGISTEREDRENTERS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRentersRegisteredRentersEditSuccess: false,
    isRentersRegisteredRentersEditError: true,
  };
}

case EDIT_RENTERS_REGISTEREDRENTERS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRentersRegisteredRentersEditSuccess: true,
    isRentersRegisteredRentersEditError: false,
  };
}

// EDIT END

// Create
case INIT_CREATE_RENTERS_REGISTEREDRENTERS_SCREEN: {
  return { ...INITIAL_STATE };
}

case CREATE_RENTERS_REGISTEREDRENTERS.ERROR: {
  const { error } = action.payload;
  return {
    ...state,
    error,
    isLoading: false,
    isRentersRegisteredRentersCreateSuccess: false,
    isRentersRegisteredRentersCreateError: true,
  };
}
case CREATE_RENTERS_REGISTEREDRENTERS.SUCCESS: {
  return {
    ...state,
    isLoading: false,
    isRentersRegisteredRentersCreateSuccess: true,
    isRentersRegisteredRentersCreateError: false,
  };
}

//End
  

      case TOAST.RESET: {
        return {
          ...state,
          isRentersRegisteredRentersCreateSuccess: false,
          isRentersRegisteredRentersCreateError: false,
          isRentersRegisteredRentersEditError: false,
          isRentersRegisteredRentersEditSuccess: false,
          isRentersRegisteredRentersDetailError: false
        };
      }
      default:
        return state;
    }
  };
  